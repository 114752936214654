export const mainMenuItems = [
  {
    name: 'HOME',
    stateName: 'main.home',
  },
  {
    name: 'WELCOME',
    stateName: 'main.welcome',
  },
  {
    name: 'MY LEARNING',
    stateName: 'main.learning',
    type: 'custom',
  },
  {
    name: 'NEWS',
    stateName: 'main.news',
  },
  {
    name: 'CALENDAR',
    stateName: 'main.calendar',
  },
  {
    name: 'CATALOG',
    stateName: 'main.fullCatalog',
    stateParams: { type: 'course' },
    data: { availableFor: ['regularUser', 'admin'] },
  },
  {
    name: 'SEARCH',
    stateName: 'main.search',
  },
  {
    name: 'ADMIN',
    href: '/lms/admin',
    data: { availableFor: ['admin'] },
  },
];
