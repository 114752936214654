import { DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { AppModule } from 'app/app.module';
import { LmsCoreModule } from 'core/core.module';
import { GlobalConfig } from 'core/environment';
import { appModuleAjs } from 'downgraded/app.module.ajs';
import { LmsModule } from 'modules/lms.module';
import runLMS from '../../app-runner';
import { mainMenuItems } from './menu-items';

@NgModule({
  imports: [BrowserModule, UpgradeModule, UIRouterUpgradeModule.forRoot(), LmsCoreModule, LmsModule, AppModule],
  declarations: [],
  // TODO Temporary workaround see @url: https://github.com/angular/angular/issues/17581
  providers: [{ provide: '$scope', useExisting: '$rootScope' }],
})
export class CapsloModule implements DoBootstrap {
  constructor(
    private upgrade: UpgradeModule,
    private globalConfig: GlobalConfig,
  ) {
    this.globalConfig.menu = mainMenuItems;
  }

  ngDoBootstrap() {
    this.upgrade.bootstrap(document.getElementsByTagName('html')[0], [appModuleAjs.name]);
  }
}

runLMS<CapsloModule>(CapsloModule);
